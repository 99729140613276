// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\src\\pages\\privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\src\\pages\\terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-scss\\.cache\\data.json")

